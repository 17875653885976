import "./Wallets.styles";
import "./wallets.module.scss";

import { forwardRef, useMemo, useState } from "react";

import { AdditionalLayout } from "components";

import { useTypedSelector } from "hooks/useTypedSelector";
import { sortBy } from "lodash";
import { NavLink, useNavigate } from "react-router-dom";
import {
  setSelectedPair,
  setSelectedPairId,
} from "store/exchange/exchange.slice";

import { Box, styled } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { addPairChangingCounter } from "store/exchange/exchange.slice";

import {
  StyledBalanceTitle,
  StyledBalanceValue,
  StyledButton,
  StyledButtonHistory,
  StyledButtonTable,
  StyledButtonWithdraw,
  StyledCoinImg,
  StyledLeftPadding,
  StyledSearchIcon,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellHead,
  StyledTableCellHeadCoin,
  StyledTableCellThin,
  StyledTableRow,
  StyledTextField,
  StyledTitle,
  WalletsWrap,
} from "./Wallets.styles";
import { IByBitWalletBalanceCoin } from "types/exchange.types";
import { useDispatch } from "react-redux";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledFieldWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  marginBottom: 50,
  paddingLeft: "64px",
}));

const Wallets = () => {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { currencies } = useTypedSelector((state) => state.exchange);
  const [search, setSearch] = useState<string>("");
  const [open, setOpen] = useState(false);

  const byBitWalletBalance = useTypedSelector(
    (state) => state.wallets.byBitWalletBalance
  );

  const setSelectedPairForTrade = (pair: string) => {
    const pairBody = currencies?.result.list.find(
      (currency) => currency.baseCoin === pair && currency.quoteCoin === "USDT"
    );
    if (pairBody) {
      dispatch(setSelectedPairId(Number(pairBody.symbol)));
      dispatch(setSelectedPair(pairBody));
      dispatch(addPairChangingCounter());
      nav(`/exchange?pair=${pairBody.baseCoin}/${pairBody.quoteCoin}`);
    }
  };

  const total = useMemo(() => {
    if (!byBitWalletBalance.data) return null;
    return byBitWalletBalance.data.list[0].totalEquity || 0;
  }, [byBitWalletBalance.data]);

  const computedBalances = useMemo(() => {
    const coins: IByBitWalletBalanceCoin[] =
      byBitWalletBalance.data?.list.reduce(
        (prev: any, item) => (item.coin ? [...prev, ...item.coin] : prev),
        []
      ) || [];
    return coins.filter(
      (item: any) =>
        !search || item.coin.toLowerCase().includes(search.toLowerCase())
    ) as IByBitWalletBalanceCoin[];
  }, [byBitWalletBalance, search]);

  return (
    <AdditionalLayout>
      <WalletsWrap>
        <StyledLeftPadding>
          <Box display="flex" className="wallets" alignItems="center">
            <StyledTitle>Wallet</StyledTitle>
            <NavLink to="/dashboard/deposit">
              <StyledButton variant="outlined">Deposit</StyledButton>
            </NavLink>
            <NavLink to="/dashboard/withdraw">
              <StyledButtonWithdraw variant="outlined">
                Withdraw
              </StyledButtonWithdraw>
            </NavLink>
            <NavLink to="/wallets/transaction-history">
              <StyledButtonHistory variant="outlined">
                Transaction History
              </StyledButtonHistory>
            </NavLink>
          </Box>
        </StyledLeftPadding>
        <StyledBalanceTitle>Total balance</StyledBalanceTitle>
        <StyledBalanceValue>
          {!!total
            ? Number(total).toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })
            : ""}
        </StyledBalanceValue>

        <StyledFieldWrapper>
          <StyledTextField
            id="input-with-icon-textfield"
            onChange={(e) => setSearch(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <StyledSearchIcon />
              </InputAdornment>
            }
            placeholder="Search for coin"
          />
        </StyledFieldWrapper>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" />
                <StyledTableCellHeadCoin align="left">
                  <Box display="flex" alignItems="center">
                    <span>Crypto coin</span>
                  </Box>
                </StyledTableCellHeadCoin>
                <StyledTableCellHead align="left">
                  <Box display="flex" alignItems="center">
                    <span>Quantity</span>
                  </Box>
                </StyledTableCellHead>
                <StyledTableCellHead align="left">
                  <Box display="flex" alignItems="center">
                    <span>USD Value</span>
                  </Box>
                </StyledTableCellHead>
              </TableRow>
            </TableHead>
            <StyledTableBody>
              {computedBalances &&
                computedBalances.length &&
                sortBy(computedBalances, "balance")
                  .reverse()
                  .map((item, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell padding="checkbox" />
                      <StyledTableCell align="left">
                        <Box display="flex" alignItems="center">
                          <CoinIcon coinName={item.coin?.toLowerCase()} style={{ width: 25, marginInline: 16 }} />
                          <span>{item.coin}</span>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {Number(item.equity)}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {Number(item.usdValue).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </StyledTableCell>
                      <StyledTableCellThin align="center">
                        <StyledButtonTable
                          variant="text"
                          onClick={() =>
                            nav(`/dashboard/deposit?coin=${item.coin}`)
                          }
                        >
                          Deposit
                        </StyledButtonTable>
                      </StyledTableCellThin>
                      <StyledTableCellThin align="center">
                        <StyledButtonTable
                          variant="text"
                          onClick={() =>
                            nav(`/dashboard/withdraw?coin=${item.coin}`)
                          }
                        >
                          Withdraw
                        </StyledButtonTable>
                      </StyledTableCellThin>

                      <StyledTableCellThin align="center">
                        {item.coin !== "USDT" && (
                          <StyledButtonTable
                            variant="text"
                            onClick={() => setSelectedPairForTrade(item.coin)}
                          >
                            Trade
                          </StyledButtonTable>
                        )}
                      </StyledTableCellThin>

                      <StyledTableCellThin />
                    </StyledTableRow>
                  ))}
            </StyledTableBody>
          </Table>
        </TableContainer>
      </WalletsWrap>
    </AdditionalLayout>
  );
};

export default Wallets;
