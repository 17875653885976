// import img from 'next/image'
import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { ArrowBack } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  setProfileError,
  setSaveMode,
} from "../../../store/profile/profile.slice";
import { loginThunk } from "../../../store/profile/profile.thunk";
import { EProfileError, ESaveMode } from "../../../types/profile.types";
import styles from "../auth.module.scss";

interface ILogin {
  setStatus: Dispatch<SetStateAction<string>>;
}

const validationSchema = yup.object({
  email: yup.string().required("Required field").email("Wrong email"),
  password: yup
    .string()
    .required("Required field")
    .min(8, "Incorrect password length"),
});

const values = {
  email: "",
  password: "",
};

const Login: FC<ILogin> = ({ setStatus }) => {
  //Redux
  const dispatch = useAppDispatch();
  const { error } = useTypedSelector((state) => state.profile);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  //Hanlders
  const onSubmit = async (values: any) => {
    const { email, password } = { ...values } as Partial<any>;
    dispatch(
      loginThunk({
        password,
        email,
      })
    )
  };

  const handleBackLogin = () => {
    dispatch(setProfileError(EProfileError.null));
  };

  const handleChangeSaveMod = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setSaveMode(
          e.target.checked ? ESaveMode.localStorage : ESaveMode.sessionStorage
        )
      );
    },
    []
  );

  useEffect(() => {
    if (error === EProfileError.OtpRequired) {
      dispatch(setProfileError(EProfileError.null));
      setStatus("2FACode");
    }
  }, [error]);

  return (
    <>
      <div style={{ marginBottom: 61 }}>
        <h1 className={styles["auth-title"]}>Log In</h1>
        <span className={styles["auth-note"]}>
          Dont have an account?
          <Link to={"/signup"}>Register now</Link>
        </span>
      </div>
      {error === EProfileError.inactiveUser ? (
        <>
          <Typography variant={"h4"}>
            You need confirm your account!{"\n"}
            We sent to your account code
          </Typography>
          <Button onClick={handleBackLogin} variant={"text"}>
            <ArrowBack /> Back to login page
          </Button>
        </>
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          {(formik) => (
            <form
              className={styles["auth-form"]}
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <input
                name="email"
                className={formik.errors.email ? styles["error"] : ""}
                onChange={(e: any) => {
                  const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
                  if (e.target.value === "" || re.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                value={formik.values.email}
                type="email"
                placeholder="E-mail *"
              />
              {formik.errors.email && (
                <p className={styles["error"]}>{formik.errors.email}</p>
              )}
              <div className={styles["auth-input--wrapper"]}>
                <img
                  src="/images/auth/eye.svg"
                  width={32}
                  height={32}
                  // onClick={(e) => showPass('password')}
                  onMouseDown={() => {
                    // const item = document.getElementById('password') as HTMLInputElement | null
                    // console.log(item)
                    // if (item !== null) {
                    //     item.type = 'text'

                    // }
                    setShowPassword(true);
                  }}
                  onMouseUp={() => {
                    // const item = document.getElementById('password') as HTMLInputElement | null
                    // console.log(item)
                    // if (item !== null) {
                    //     item.type = 'password'

                    // }
                    setShowPassword(false);
                  }}
                  onMouseLeave={() => {
                    if (showPassword) {
                      setShowPassword(false);
                    }
                  }}
                  alt="show password"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <input
                  id="password"
                  className={formik.errors.password ? styles["error"] : ""}
                  name="password"
                  onChange={(e) => {
                    const re = /^[A-Za-z0-9/!@#$%.,_\^&*()={}:;<>+'-]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.password}
                  type={showPassword ? "text" : "password"}
                  placeholder="Password *"
                />
              </div>
              {formik.errors.password && (
                <p className={styles["error"]}>{formik.errors.password}</p>
              )}
              {error === EProfileError.incorrectAuthData && (
                <span className={styles["error"]}>
                  Incorrect email or password
                </span>
              )}
              <div className={styles["auth-input--wrapper"]}>
                <div className={styles["form-checkbox"]}>
                  <label>
                    {/* <input
												onChange={handleChangeSaveMod}
												name="remember"
												// value={formik.values.policyAgreement}
												// onChange={formik.handleChange}
												type="checkbox"
												id="checkbox1"
										/> */}
                    <div style={{ display: "inline" }}>
                      <div className="form-group">
                        {/* <input checked={props.checked} onChange={props.changeEvent} />pe="checkbox" id="html"/> */}
                        <input
                          type="checkbox"
                          onChange={handleChangeSaveMod}
                          id="html"
                        />
                        <label htmlFor="html" style={{ color: "#fff" }}></label>
                      </div>
                    </div>
                    Remember me
                  </label>
                </div>
              </div>

              <button className={styles["auth-form--btn"]} type="submit">
                Log In
              </button>
            </form>
          )}
        </Formik>
      )}

      <Link to={"/auth/forgot-password"}>
        <Typography
          className={"hover"}
          mt={3}
          align={"center"}
          color={"#0157FF"}
          variant={"h6"}
        >
          Forgot Your Password?
        </Typography>
      </Link>
    </>
  );
};

export default Login;
