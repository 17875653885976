import "./HeaderStyles.scss";

// import Image from 'next/image'
import { CSSProperties, FC, MouseEvent, useState } from "react";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { storageService } from "services/storage.service";
import { logoutThunk } from "store/profile/profile.thunk";
import { headerNav } from "utils";

import { IconButton } from "@mui/material";

import { BLogo } from "../UI/atoms/BLogo/BLogo";
import { StyledMenu, StyledMenuItem } from "./Header.styles";
import HeaderNavItem from "./HeaderNavItem";

interface IProps {
  style?: CSSProperties;
}

export const CustomHeader: FC<IProps> = ({ style }): JSX.Element => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    setAnchorEl(null);
    await dispatch(logoutThunk(storageService.getRefreshToken));
    window.location.reload();
  };

  const nav = useNavigate();
  return (
    <header
      className={`customHeader ${
        document.location.href.split("/")[3] === "exchange" && "darkHeader"
      }`}
      style={style}
    >
      <div className="customHeader_grid">
        <BLogo onClick={() => nav("/dashboard/overview")} />
        <div className="customHeader_block">
          {headerNav.map((navItem, index) => {
            return <HeaderNavItem key={index} {...navItem} index={index} />;
          })}
        </div>
      </div>

      <div className="customHeader_block profileNsettings">
        <div className="customHeader_block__avatar">
          <IconButton
            id="basic-button"
            sx={{
              border: "1.5px solid #FFFFFF",
              borderRadius: "50%",
              width: "39px",
              height: "39px",
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img src={"/images/auth/profile_icon.svg"} />
          </IconButton>
          <StyledMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <StyledMenuItem onClick={handleLogout}>Logout</StyledMenuItem>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </StyledMenu>
        </div>
        {/* <div
          className="customHeader_tabbing"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <img
            style={{ cursor: "pointer", marginRight: "10px" }}
            src="/images/languageIcon.svg"
            alt={`icon`}
            height={30}
            width={30}
          />
          <p
            style={{
              color: "#FFF",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "16px",
            }}
          >
            English
          </p>
        </div> */}
      </div>
    </header>
  );
};

// export default CustomHeader
