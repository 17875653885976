import "./History.style.scss";

import { useState } from "react";

import { AdditionalLayout } from "components";
import { useNavigate } from "react-router-dom";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Alert,
  InputAdornment,
  Menu,
  Snackbar,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import {
  StyledFieldWrapper,
  StyledSearchIcon,
  StyledTextField,
} from "../CommonComponents.styles";

import { StyledTitle, StyledType } from "./History.styles";
import { WithdrawsAndDepositsHistoryContainer } from "components/container/WithdrawsAndDepositsHistoryContainer";
import { DepositsHistoryContainer } from "components/container/DepositsHistoryContainer";
import { WithdrawsHistoryContainer } from "components/container/WithdrawsHistoryContainer";
import { TransactionTable } from "./TransactionsTable";

type TransactionType = "all" | "withdraw" | "deposit";

const Tabs = () => {
  const [transactionType, setTransactionType] =
    useState<TransactionType>("all");
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openCopied, setOpenCopied] = useState<boolean>(false);
  const navigate = useNavigate();

  return (
    <>
      <AdditionalLayout>
        <div style={{ backgroundColor: "#fff", padding: 30, margin: 0 }}>
          <StyledTitle>Transaction history </StyledTitle>
          <div className="container">
            <div style={{ paddingLeft: "17px" }}>
              <StyledType>Type</StyledType>
              <div style={{ display: "flex" }}>
                <Button
                  style={{
                    background: "#fff",
                    border: "1px solid #DBD9D9",
                    borderRadius: 6,
                    color: "#000000",
                    padding: 0,
                    height: 30,
                    width: 127,
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "18px",
                    textTransform: "capitalize",
                  }}
                  disableElevation
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {transactionType}
                </Button>
                <Menu
                  variant="selectedMenu"
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={!!anchorEl}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => setTransactionType("all")}
                    disableRipple
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    onClick={() => setTransactionType("deposit")}
                    disableRipple
                  >
                    Deposit
                  </MenuItem>
                  <MenuItem
                    onClick={() => setTransactionType("withdraw")}
                    disableRipple
                  >
                    Widthdraw
                  </MenuItem>
                </Menu>
                <StyledFieldWrapper style={{ marginLeft: "66px" }}>
                  <StyledTextField
                    id="input-with-icon-textfield"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <StyledSearchIcon />
                      </InputAdornment>
                    }
                    placeholder="Search for coin"
                  />
                </StyledFieldWrapper>
              </div>
            </div>
            <Typography
              onClick={() => navigate("/dashboard/helpdesk")}
              style={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "18px",
                textDecorationLine: "underline",
                color: "#00B7F1",
                cursor: "pointer",
                margin: "0 0 38px 0",
              }}
            >
              Deposit has not arrived? Click here for helpdesk
            </Typography>

            {transactionType === "all" && (
              <WithdrawsAndDepositsHistoryContainer
                render={({ data, loading }) => (
                  <>
                    {data && (
                      <TransactionTable
                        data={[
                          ...(data?.deposits?.rows ? data.deposits.rows : []).map((item) => ({
                            createTime: (item as any)?.successAt,
                            type: "Deposit",
                            coin: item.coin,
                            amount: item.amount,
                            address: item.toAddress,
                            txID: item.txID,
                            status: item.status === 0 ? "unknown" : (item.status === 1 || item.status === 2) ? "new" : item.status === 3 ? "finished" : "failed",
                          })),
                          ...(data?.withdraws?.rows ? data.withdraws.rows : []).map((item) => ({
                            createTime: String(item.request.timestamp),
                            type: "Withdraw",
                            coin: item.request.coin,
                            amount: item.request.amount,
                            address: item.request.address,
                            txID: item.id,
                            status: !item.done ? "new" : !!item.done && item.error === '' ? "finished" : !!item.done && item.error !== '' ? "failed" : "unknown", 
                          })),
                        ].filter((item) =>
                          item.coin
                            ?.toLowerCase()
                            .includes(search.toLowerCase())
                        ).sort((a, b) => {
                          if(a.createTime > b.createTime) return -1;
                          if(a.createTime < b.createTime) return 1;
                          return 0;
                        })}
                      />
                    )}
                  </>
                )}
              />
            )}
            {transactionType === "deposit" && (
              <DepositsHistoryContainer
                render={({ depositsHistory }) => (
                  <>
                    {depositsHistory && (
                      <TransactionTable
                        data={(!!depositsHistory?.rows ? depositsHistory.rows : [])
                          .map((item) => ({
                            createTime: (item as any)?.successAt,
                            type: "Deposit",
                            coin: item.coin,
                            amount: item.amount,
                            address: item.toAddress,
                            txID: item.txID,
                            status: item.status === 0 ? "unknown" : (item.status === 1 || item.status === 2) ? "new" : item.status === 3 ? "finished" : "failed",
                          }))
                          .filter((item) =>
                            item.coin
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          ).sort((a, b) => {
                            if(a.createTime > b.createTime) return -1;
                            if(a.createTime < b.createTime) return 1;
                            return 0;
                          })
                        }
                      />
                    )}
                  </>
                )}
              />
            )}
            {transactionType === "withdraw" && (
              <WithdrawsHistoryContainer
                render={({ withdrawsHistory }) => (
                  <>
                    {withdrawsHistory && (
                      <TransactionTable
                        data={(!!withdrawsHistory?.rows ? withdrawsHistory.rows : [])
                          .map((item) => ({
                            createTime: String(item.request.timestamp),
                            type: "Withdraw",
                            coin: item.request.coin,
                            amount: item.request.amount,
                            address: item.request.address,
                            txID: item.id,
                            status: !item.done ? "new" : item.done && item.error !== '' ? "finished" : item.done && !!item.error ? "failed" : "unknown",
                          }))
                          .filter((item) =>
                            item.coin
                              ?.toLowerCase()
                              .includes(search.toLowerCase())
                          )}
                      />
                    )}
                  </>
                )}
              />
            )}
          </div>
        </div>
      </AdditionalLayout>

      <Snackbar
        open={openCopied}
        autoHideDuration={6000}
        onClose={setOpenCopied.bind(null, false)}
      >
        <Alert
          onClose={setOpenCopied.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          Copied!
        </Alert>
      </Snackbar>
    </>
  );
};

export default Tabs;
