import './dashboardStyles.scss'

// import Image from 'next/image'
import { FC, useEffect, useState } from 'react'

import { useTypedSelector } from 'hooks/useTypedSelector'
import { formatPercentage } from 'utils/exchange.utils'

import styled from '@emotion/styled'
import {
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import { withByBitTicker } from 'components/ByBitTickerHOC'
import { CoinIcon } from 'components/UI/atoms/CoinIcon/CoinIcon'
import { ByBitTickerData } from 'providers/ByBitProvider'
import { LinearPerpetual } from 'types/exchange.types'

const TrendingBlock: FC = (): JSX.Element => {
	const currencies = useTypedSelector(state => state.exchange.currencies)
	const [trendingCurrencies, setTrendingCurrencies] = useState<any[]>([])
	const emptyArr = [{}, {}, {}]
	const useStyles = makeStyles({
		cell: {
			fontSize: '14px',
		},
	})
	const classes = useStyles()

	// const trendingCurrencies = useMemo(() => {
	// 	// TODO: Add trending sort logic
	// 	if (!currencies) return []
	// 	return currencies.result.list
	// 		.filter(item => item.quoteCoin === 'USDT')
	// 		.slice(0, 9)
	// 	// .sort((a, b) => b. - a.price24hPcnt)
	// }, [currencies])

	useEffect(() => {
		;(async () => {
			if (!currencies) return
			const trendingCurrencies = currencies.result.list.filter(
				item => item.quoteCoin === 'USDT'
			)
			const resTrendingCurrencies = await Promise.allSettled(
				trendingCurrencies.map(async item => {
					const response = await axios.get(
						`https://api.bybit.com/v5/market/tickers?category=spot&symbol=${item.symbol}`
					)
					return response.data.result.list[0]
				})
			)
			const result = [...resTrendingCurrencies]
				.sort((a: any, b: any) => {
					if (+a?.value?.volume24h > +b?.value?.volume24h) return -1
					if (+a?.value?.volume24h < +b?.value?.volume24h) return 1
					return 0
				})
				.map((item: any) => item.value)
			const filterCurrencies = []
			for (const resultItem of result) {
				if (!resultItem) {
					continue
				}
				for (const item of currencies.result.list) {
					if (item.symbol === resultItem.symbol) {
						filterCurrencies.push(item)
					}
				}
			}
			setTrendingCurrencies(filterCurrencies.slice(0, 9))
		})()
	}, [currencies])

	return (
		<div className='background mb20  trendingBlock'>
			<h3 style={{ paddingTop: 24, paddingLeft: 30 }}>Trending</h3>
			<StyledTableContainer
				sx={{
					boxShadow: 'none',
					background: '#white !important',
					paddingTop: 0,
				}}
				className='paddingSmall'
			>
				<Table
					style={{
						borderCollapse: 'separate',
						background: '#fff  !important',
						borderSpacing: '10px 16px',
					}}
					aria-label='simple table'
				>
					<TableHead
						style={{
							color: '#80848E',
							marginBottom: 20,
							fontSize: '14 !important',
						}}
					>
						<TableRow style={{ marginTop: '12px' }}>
							<TableCell style={{ color: '#80848E', marginBottom: 20 }}>
								<p className={classes.cell}>Coin</p>
							</TableCell>
							<TableCell
								style={{ color: '#80848E', marginBottom: 20, fontSize: 14 }}
								align='left'
							>
								<p className={classes.cell}>Price</p>
							</TableCell>
							<TableCell
								style={{ color: '#80848E', marginBottom: 20, fontSize: 14 }}
								align='left'
							>
								<p className={classes.cell}>24h change</p>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{!trendingCurrencies &&
							emptyArr.length &&
							emptyArr.map((_, index) => (
								<div className='tableBlock_grid' key={index}>
									<div className='tableBlock_td'>
										<div className='coinLogo'>
											<Skeleton variant='circular' width={21} height={21} />
										</div>
										<Skeleton variant='text' width={70} height={24} />
									</div>
									<p className='tableBlock_td'>
										<Skeleton variant='text' width={70} height={24} />
									</p>
									<p className='tableBlock_td green textAlignRight'>
										<Skeleton variant='text' width={70} height={24} />
									</p>
								</div>
							))}
						{((trendingCurrencies && trendingCurrencies) || []).map(
							(trendCoin, index) => (
								<>{trendCoin && <Row key={index} data={trendCoin} />}</>
							)
						)}
					</TableBody>
				</Table>
			</StyledTableContainer>

			{/* <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div>
                    <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div>
                    <div className="tableBlock_grid">
                        <div className="tableBlock_td">
                            <div className="coinLogo">
                                <img
                                    src="/images/coins/bitcoin.svg"
                                    alt="bitocol bitcoin icon"
                                    width={21}
                                    height={21}
                                />
                            </div>
                            ETH/
                            <span className="currency">EUR</span>
                        </div>
                        <p className="tableBlock_td">€2.567</p>
                        <p className="tableBlock_td green textAlignRight">
                            +3.5%
                        </p>
                    </div> */}
			{/* </div>
            </div> */}
		</div>
	)
}

const Row = withByBitTicker(
	({ value: row }: { value: LinearPerpetual & ByBitTickerData }) => {
		return (
			<TableRow
				key={row.symbol}
				style={{ borderBottom: '1px solid red' }}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell
					style={{
						width: '43%',
						textAlign: 'left',
						display: 'flex',
						alignItems: 'center',
					}}
					component='th'
					scope='row'
				>
					<div className='coinLogo' style={{ marginRight: 14 }}>
						<CoinIcon coinName={row.baseCoin} />
					</div>
					<span
						style={{
							fontFamily: 'Poppins',
							fontWeight: 400,
							fontSize: '14px',
							color: '#000',
							lineHeight: '21px',
						}}
					>
						{' '}
						{row.baseCoin.trim()}/
					</span>
					<span
						style={{
							fontFamily: 'Poppins',
							fontWeight: 400,
							fontSize: '14px',
							color: '#80848E',
							lineHeight: '21px',
						}}
						className='currency'
					>
						{row.quoteCoin.trim()}
					</span>
				</TableCell>
				<TableCell style={{ width: '27%', textAlign: 'left' }} align='right'>
					<p className='tableBlock_td'>
						{Number(row.lastPrice).toLocaleString('en-US', {
							style: 'currency',
							currency: 'USD',
						})}
					</p>
				</TableCell>
				<TableCell style={{ width: '30%', textAlign: 'left' }} align='right'>
					{' '}
					<p
						className={`tableBlock_td textAlignRight ${
							parseFloat(row.price24hPcnt) >= 0 ? 'green' : 'red'
						}`}
					>
						{parseFloat(row.price24hPcnt) >= 0
							? `+${formatPercentage(parseFloat(row.price24hPcnt) * 100)}%`
							: `${formatPercentage(parseFloat(row.price24hPcnt) * 100)}%`}
					</p>
				</TableCell>
			</TableRow>
		)
	}
)

const StyledTableContainer = styled(TableContainer)`
	background: white !important;
	background-color: white !important;
`

export default TrendingBlock
