import { forwardRef, useEffect, useState } from "react";

import { useAppDispatch } from "hooks/useAppDispatch";
import { setChangePassword, setRequestChangePassword } from "store/profile/profile.slice";
import {
	changePasswordThunk,
	getCodeThunk,
	requestChangePasswordThunk,
} from "store/profile/profile.thunk";

import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, DialogContent, IconButton, InputAdornment } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
	StyledButtonCode,
	StyledCodesWrapper,
	StyledDialog,
	StyledDialogTitle,
	StyledEmailLabel,
	StyledEmailTitle,
	StyledInputCode,
	StyledPasswordsWrapper,
	StyledTextField,
} from "../Settings.style";
import { SuccessSentForPasswordChange } from "./SuccessSent";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const ChangePassword = ({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
}) => {
	const dispatch = useAppDispatch();
	const [sendPhoneCodeSuccess, setSendPhoneCodeSuccess] = useState(false);
	const [sendEmailCodeSuccess, setSendEmailCodeSuccess] = useState(false);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordAgain, setNewPasswordAgain] = useState("");
	// const [phoneCode, setPhoneCode] = useState("");
	const [emailCode, setEmailCode] = useState("");

	const {
		loading: requestChangePasswordLoading,
		response: requestChangePasswordResponse,
		error: requestChangePasswordError,
	} = useTypedSelector((state) => state.profile.requestChangePassword);
	const {
		loading: changePasswordLoading,
		response: changePasswordResponse,
		error: changePasswordError,
	} = useTypedSelector((state) => state.profile.changePassword);
	const [showPass, setShowPass] = useState(false);
	const [showPassConf, setShowPassConf] = useState(false);
	const [currentPass, setCurrentPass] = useState(false);

	const handleChangePassword = async (event: any) => {
		event.preventDefault()
		await dispatch(
			changePasswordThunk({
				password: newPassword,
				code: emailCode,
				old_password: password,
			})
		);
		setOpen(false);
	};

	const handleRequestCodeEmail = () => {
		dispatch(requestChangePasswordThunk());
		setSendEmailCodeSuccess(true);
	};

	const phone = useTypedSelector((state) => state.profile.phone);
	const handleRequestCodePhone = () => {
		dispatch(
			getCodeThunk({
				code: phone.data.code,
				number: phone.data.number as string,
			})
		);
		setSendPhoneCodeSuccess(true);
	};

	const [openSuccess, setOpenSuccess] = useState(false);
	const [openError, setOpenError] = useState(false);

	useEffect(() => {
		if (requestChangePasswordResponse || changePasswordResponse) {
			setOpen(true);
			dispatch(
				setChangePassword({
					loading: false,
					response: null,
					error: null,
				})
			);
			dispatch(
				setRequestChangePassword({
					loading: false,
					response: null,
					error: null,
				})
			);
		}
	}, [requestChangePasswordResponse, changePasswordResponse]);

	useEffect(() => {
		if (requestChangePasswordError || changePasswordError) {
			setOpenError(true);
			dispatch(
				setChangePassword({
					loading: false,
					response: null,
					error: null,
				})
			);
			dispatch(
				setRequestChangePassword({
					loading: false,
					response: null,
					error: null,
				})
			);
		}
	}, [requestChangePasswordError, changePasswordError]);

	return (
		<StyledDialog open={open} onClose={setOpen.bind(null, false)}>
			<StyledDialogTitle>
				<Box display='flex' alignItems='center' justifyContent='space-between'>
					<span>Change password</span>
					<IconButton onClick={setOpen.bind(null, false)}>
						<CloseIcon />
					</IconButton>
				</Box>
			</StyledDialogTitle>
			<DialogContent>
				{/* <StyledEmailTitle>New email verification</StyledEmailTitle> */}
				<StyledEmailLabel>Current password</StyledEmailLabel>

				<StyledTextField
					sx={{
						"& fieldset": { border: "none" },
						"& .MuiInputBase-input": {
							padding: "0  !important",
						},
					}}
					type={currentPass ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment style={{ position: "absolute", right: -8 }} position='end'>
								<IconButton onClick={() => setCurrentPass(!currentPass)}>
									{currentPass ? <Visibility color='primary' /> : <VisibilityOff color='primary' />}
								</IconButton>
							</InputAdornment>
						),
					}}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				<StyledPasswordsWrapper display='flex' alignItems='center' justifyContent='space-between'>
					<div>
						<StyledEmailLabel>New password</StyledEmailLabel>
						<StyledTextField
							sx={{
								"& fieldset": { border: "none" },
								"& .MuiInputBase-input": {
									padding: "0  !important",
								},
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment style={{ position: "absolute", right: -8 }} position='end'>
										<IconButton onClick={() => setShowPass(!showPass)}>
											{showPass ? (
												<Visibility color='primary' />
											) : (
												<VisibilityOff color='primary' />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
							type={showPass ? "text" : "password"}
							value={newPassword}
							onChange={(e) => {
								setNewPassword(e.target.value);
							}}
						/>
					</div>
					<div>
						<StyledEmailLabel>New password verification</StyledEmailLabel>
						<StyledTextField
							sx={{
								"& fieldset": { border: "none" },
								"& .MuiInputBase-input": {
									padding: "0  !important",
								},
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment style={{ position: "absolute", right: -8 }} position='end'>
										<IconButton onClick={() => setShowPassConf(!showPassConf)}>
											{showPassConf ? (
												<Visibility color='primary' />
											) : (
												<VisibilityOff color='primary' />
											)}
										</IconButton>
									</InputAdornment>
								),
								disableUnderline: true,
								style: {
									height: 33,
								},
							}}
							type={showPassConf ? "text" : "password"}
							value={newPasswordAgain}
							onChange={(e) => setNewPasswordAgain(e.target.value)}
						/>
					</div>
				</StyledPasswordsWrapper>

				<StyledEmailTitle>Security verification</StyledEmailTitle>

				<StyledCodesWrapper display='flex' alignItems='center' justifyContent='space-between'>
					{/* <div>
						<StyledEmailLabel>Phone number verification code</StyledEmailLabel>

						<Box display='flex' alignItems='center'>
							<StyledInputCode
								type='text'
								value={phoneCode}
								onChange={(e) => setPhoneCode(e.target.value)}
							/>
							<StyledButtonCode onClick={handleRequestCodePhone}>Get code</StyledButtonCode>
						</Box>
						{sendPhoneCodeSuccess && <SuccessSentForPasswordChange />}
					</div> */}
					<div>
						<StyledEmailLabel>Email verification code</StyledEmailLabel>

						<Box display='flex' alignItems='center'>
							<StyledInputCode
								type='text'
								value={emailCode}
								onChange={(e) => setEmailCode(e.target.value)}
							/>

							<StyledButtonCode onClick={handleRequestCodeEmail}>Get code</StyledButtonCode>
						</Box>

						{sendEmailCodeSuccess && <SuccessSentForPasswordChange />}
					</div>
				</StyledCodesWrapper>

				<Button
					disabled={
						password === "" ||
						newPassword === "" ||
						newPasswordAgain === "" ||
						emailCode === ""
					}
					variant='outlined'
					size='small'
					style={{
						color: "#B3945E",
						border: " 1.5px solid #B3945E",
						width: 152,
						height: 29,
						cursor: "pointer",
						fontWeight: 400,
						fontSize: "12px",
						lineHeight: "15px",
						textTransform: "initial",
						borderRadius: "3px",
					}}
					onClick={handleChangePassword}>
					Change password
				</Button>
			</DialogContent>

			<Snackbar
				open={openSuccess}
				autoHideDuration={6000}
				onClose={setOpenSuccess.bind(null, false)}>
				<Alert onClose={setOpenSuccess.bind(null, false)} severity='success' sx={{ width: "100%" }}>
					Data sent successfully!
				</Alert>
			</Snackbar>

			<Snackbar open={openError} autoHideDuration={6000} onClose={setOpenError.bind(null, false)}>
				<Alert onClose={setOpenError.bind(null, false)} severity='error' sx={{ width: "100%" }}>
					{requestChangePasswordError?.detail ||
						changePasswordError?.detail ||
						"An error has occurred"}
				</Alert>
			</Snackbar>
		</StyledDialog>
	);
};
