import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
	Box,
	Button,
	IconButton,
	Tab,
	Tabs,
	TextField,
	Typography,
} from '@mui/material'
import { ByBitPosition, byBitApi } from 'api'
import { withRealtimeByBitTicker } from 'components/ByBitTickerHOC'
import { removeTrailingZeros } from 'helpers/removeTrailingZeros'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { useSnackbar } from 'notistack'
import { useByBit } from 'providers/ByBitProvider'
import {
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import {
	addPairChangingCounter,
	setSelectedPair,
	setSelectedPairId,
} from 'store/exchange/exchange.slice'
import { z } from 'zod'
import {
	AddTakeProfitStopLossModal,
	TakeProfitStopLossSchema,
} from '../AddTakeProfitStopLossModal'
import { PnLShareModal } from '../PnLShareModal'

interface IProps {
	items: ByBitPosition[]
	title: string
	renderMessage: (args: {
		item: ByBitPosition | null
		setItem: Dispatch<SetStateAction<ByBitPosition | null>>
		handleClose: () => void
	}) => ReactNode
}
export type ByBitTickerData = {
	symbol: string
	tickDirection: string
	price24hPcnt: string
	lastPrice: string
	prevPrice24h: string
	highPrice24h: string
	lowPrice24h: string
	prevPrice1h: string
	markPrice: string
	indexPrice: string
	openInterest: string
	openInterestValue: string
	turnover24h: string
	volume24h: string
	nextFundingTime: string
	fundingRate: string
	bid1Price: string
	bid1Size: string
	ask1Price: string
	ask1Size: string
}

export type ByBitTicker = {
	topic: string
	type: 'snapshot' | 'delta'
	data: ByBitTickerData
	cs: number
	ts: number
}

const closeOrderLimitSchema = z.object({
	type: z.number(),
	price: z.string().min(1, { message: 'Price is required' }),
})
type CloseOrderLimitSchema = z.infer<typeof closeOrderLimitSchema>

const HEADS = [
	'Contracts',
	'Qty',
	'Value',
	'Entry Price',
	'Mark Price',
	'Liq. Price',
	'IM',
	'MM',
	'Unrealized P&L(%)',
	'Realized P&L',
	'TP/SL',
	' ',
]

export const ExchangePositions: FC<IProps> = ({
	items,
	title,
	renderMessage,
}: IProps) => {
	const selectedPair = useTypedSelector(state => state.exchange.selectedPair)
	const [positionForShare, setPositionForShare] = useState<
		(ByBitPosition & { lastPrice: string }) | null
	>(null)
	const [positionForClose, setPositionForClose] =
		useState<ByBitPosition | null>(null)
	const [positionForTPLS, setPositionForTPLS] = useState<ByBitPosition | null>(
		null
	)
	const { currencies } = useTypedSelector(state => state.exchange)
	const { enqueueSnackbar } = useSnackbar()
	const dispatch = useAppDispatch()

	const positionForShareInfo = useMemo(() => {
		const roiValue =
			(+(positionForShare?.unrealisedPnl ?? 0) /
				(+(positionForShare?.avgPrice ?? 0) * +(positionForShare?.size ?? 0))) *
			100
		const roi = `${Math.trunc(roiValue * 100) / 100}%`

		const pnlValue =
			(+(positionForShare?.unrealisedPnl ?? 0) /
				(+(positionForShare?.avgPrice ?? 0) * +(positionForShare?.size ?? 0))) *
			(+(positionForShare?.avgPrice ?? 0) * +(positionForShare?.size ?? 0))
		const pnl = `${Math.trunc(pnlValue * 100) / 100}`

		return {
			fromType: 'position',
			symbol: positionForShare?.symbol,
			entryPrice: +(positionForShare?.avgPrice ?? 0),
			filledPrice: +(positionForShare?.lastPrice ?? 0),
			side: positionForShare?.side,
			leverage: +(positionForShare?.leverage ?? 0),
			roi,
			pnl,
			isRoiIncrease: roiValue >= 0,
			isPnlIncrease: +(positionForShare?.unrealisedPnl ?? 0) >= 0,
		}
	}, [positionForShare])

	const handleConfirm = async (data: TakeProfitStopLossSchema) => {
		if (!positionForTPLS) return

		// if(positionForTPLS.side === 'Buy') {
		//   if(!!data.profit && +data.profit > (+positionForTPLS.avgPrice + (+positionForTPLS.avgPrice * 1.5))) {
		//     enqueueSnackbar("The take profit cannot be higher than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(data.profit && +data.profit < +positionForTPLS.avgPrice) {
		//     enqueueSnackbar("The take profit cannot be lower than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(data.loss && +data.loss > +positionForTPLS.avgPrice) {
		//     enqueueSnackbar("The loss stop cannot be higher than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(!!data.loss && +data.loss < (+positionForTPLS.avgPrice - (+positionForTPLS.avgPrice * 0.75))) {
		//     enqueueSnackbar("The loss stop cannot be lower than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }
		// }
		// if(positionForTPLS.side === 'Sell') {
		//   if(!!data.profit && +data.profit < 0) {
		//     enqueueSnackbar("The stop loss cannot be lower than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(data.profit && +data.profit > +positionForTPLS.avgPrice) {
		//     enqueueSnackbar("The stop loss cannot be higher than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(!!data.loss && +data.loss > (+positionForTPLS.avgPrice + (+positionForTPLS.avgPrice * 0.75))) {
		//     enqueueSnackbar("The stop loss cannot be higher than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }

		//   if(data.loss && +data.loss < +positionForTPLS.avgPrice) {
		//     enqueueSnackbar("The stop loss cannot be lower than order price", {
		//       variant: "error",
		//       anchorOrigin: {
		//         vertical: "top",
		//         horizontal: "center",
		//       },
		//     });
		//     return
		//   }
		// }

		try {
			const profitData = data.profit ? { takeProfit: String(data.profit) } : {}
			const lossData = data.loss ? { stopLoss: String(data.loss) } : {}
			await byBitApi.setTradingStop({
				category: 'linear',
				symbol: positionForTPLS.symbol,
				positionIdx: 0,
				tpslMode: 'Full',
				...profitData,
				...lossData,
			})
			setPositionForTPLS(null)
		} catch (err) {
			console.log(err)
			enqueueSnackbar('Failed to set TP/SL', {
				variant: 'error',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			})
		}
	}

	const handleClosePositionForShare = useCallback(() => {
		setPositionForShare(null)
	}, [])

	const handleClickContract = (contract: ByBitPosition) => () => {
		const filteredCurrencies = currencies?.result.list.filter(
			currency => String(currency.symbol) === String(contract.symbol)
		)
		if (filteredCurrencies && filteredCurrencies.length) {
			dispatch(setSelectedPair(filteredCurrencies[0]))
		}

		dispatch(setSelectedPairId(+contract.symbol))
		dispatch(addPairChangingCounter())
	}

	return (
		<Box mt={2} width='100%'>
			<table>
				<thead>
					<tr>
						{HEADS.map((head, headIdx) => (
							<th key={headIdx} style={{ paddingInline: '16px' }}>
								<Box
									component='span'
									fontSize='12px'
									fontWeight='400'
									color='#52555E'
									lineHeight='18px'
									whiteSpace='nowrap'
								>
									{head}
								</Box>
							</th>
						))}
					</tr>
				</thead>
				{/* <Box width="100%" height="1px" bgcolor="rgba(36, 45, 57, 0.6)" mt="16px" mb="16px"></Box> */}
				<tbody>
					{items.map((item, itemIdx) => {
						return (
							<ExchangePositionsRow
								key={itemIdx}
								data={item as any}
								selectedPair={selectedPair}
								onClickContract={handleClickContract}
								setPositionForShare={setPositionForShare}
								setPositionForTPLS={setPositionForTPLS}
								setPositionForClose={setPositionForClose}
							/>
						)
					})}
				</tbody>
			</table>
			{renderMessage({
				item: positionForClose,
				setItem: setPositionForClose,
				handleClose: () => setPositionForClose(null),
			})}
			<AddTakeProfitStopLossModal
				item={
					positionForTPLS
						? {
								price: positionForTPLS.avgPrice,
								side: positionForTPLS.side,
								qty: positionForTPLS.size,
								liqPrice: positionForTPLS.liqPrice,
								symbol: positionForTPLS.symbol,
								takeProfit: positionForTPLS.takeProfit,
								stopLoss: positionForTPLS.stopLoss,
						  }
						: null
				}
				onClose={() => setPositionForTPLS(null)}
				show={{ liqPrice: true }}
				onConfirm={handleConfirm}
			/>
			<PnLShareModal
				positionInfo={positionForShareInfo}
				open={Boolean(positionForShare)}
				onClose={handleClosePositionForShare}
			/>
		</Box>
	)
}

const ExchangePositionsRow = withRealtimeByBitTicker(
	({
		data: item,
		value: tickerItem,
		onClickContract,
		setPositionForShare,
		setPositionForTPLS,
		setPositionForClose,
		selectedPair,
	}: any) => {
		const { currencies } = useTypedSelector(state => state.exchange)
		const { ticker } = useByBit()
		const dispatch = useAppDispatch()

		return (
			<tr>
				<td style={{ paddingInline: '16px' }}>
					<Box
						component='button'
						sx={{
							position: 'relative',
							paddingLeft: '12px',
							background: 'transparent',
							cursor: 'pointer',
							'&::before': {
								content: '""',
								position: 'absolute',
								top: 0,
								left: 0,
								width: '4px',
								height: '100%',
								background: item.side === 'Buy' ? '#419E6A' : '#D83232',
							},
						}}
						onClick={onClickContract(item)}
					>
						<Typography fontWeight='600'>{item.symbol}</Typography>
						<Typography
							display='flex'
							alignItems='center'
							gap='5px'
							fontWeight='600'
							color={item.side == 'Buy' ? '#419E6A' : '#D83232'}
						>
							<span>{item.side === 'Buy' ? 'Long' : 'Short'}</span>
							<span>{(+item.leverage).toFixed(2)}x</span>
						</Typography>
					</Box>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Typography
						fontWeight='600'
						color={item.side === 'Buy' ? '#419E6A' : '#D83232'}
					>
						{item.size}
					</Typography>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Box whiteSpace='nowrap'>
						<Typography component='span' fontWeight={600}>
							{removeTrailingZeros(
								String((+item.avgPrice * +item.size).toFixed(4))
							)}{' '}
						</Typography>
						<Typography component='span' fontWeight={600}>
							{selectedPair?.quoteCoin}
						</Typography>
					</Box>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Typography fontWeight={600}>
						{removeTrailingZeros(item.avgPrice)}
					</Typography>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Typography fontWeight={600}>
						{removeTrailingZeros(String(tickerItem.markPrice ?? 0))}
					</Typography>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Typography fontWeight={600} color={'#00B7F1'}>
						{!!item.liqPrice ? item.liqPrice : '--'}
					</Typography>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Box whiteSpace='nowrap'>
						<Typography fontWeight={600}>
							{item.positionIM} {selectedPair?.quoteCoin}
						</Typography>
						<Typography fontWeight={600}>
							≈{Number(item.positionIM).toFixed(2)}{' '}
							{selectedPair?.quoteCoin.slice(0, -1)}
						</Typography>
					</Box>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Box whiteSpace='nowrap'>
						<Typography fontWeight={600}>
							{item.positionMM} {selectedPair?.quoteCoin}
						</Typography>
						<Typography fontWeight={600}>
							≈{Number(item.positionMM).toFixed(2)}{' '}
							{selectedPair?.quoteCoin.slice(0, -1)}
						</Typography>
					</Box>
				</td>
				<td style={{ paddingInline: '16px' }}>
					{!!item.unrealisedPnl ? (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box>
								<Typography
									fontWeight={600}
									color={
										(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
										(item.side === 'Sell' && +item.unrealisedPnl < 0)
											? '#419E6A' // Green color for profit
											: '#D83232' // Red color for loss
									}
								>
									{(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
									(item.side === 'Sell' && +item.unrealisedPnl < 0)
										? '+'
										: '-'}
									{String(Number(item.unrealisedPnl).toFixed(4)).replace(
										'-',
										''
									)}{' '}
									{selectedPair?.quoteCoin}
								</Typography>
								<Typography
									fontWeight={600}
									color={
										(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
										(item.side === 'Sell' && +item.unrealisedPnl < 0)
											? '#419E6A' // Green color for profit
											: '#D83232' // Red color for loss
									}
								>
									(
									{(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
									(item.side === 'Sell' && +item.unrealisedPnl < 0)
										? '+'
										: '-'}
									{String(
										(
											(+item.unrealisedPnl / (+item.avgPrice * +item.size)) *
											100
										).toFixed(2)
									).replace('-', '')}
									%)
								</Typography>
								<Typography
									fontWeight={600}
									color={
										(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
										(item.side === 'Sell' && +item.unrealisedPnl < 0)
											? '#419E6A' // Green color for profit
											: '#D83232' // Red color for loss
									}
								>
									≈
									{(item.side === 'Buy' && +item.unrealisedPnl >= 0) ||
									(item.side === 'Sell' && +item.unrealisedPnl < 0)
										? '+'
										: '-'}
									{String(
										(
											(+item.unrealisedPnl / (+item.avgPrice * +item.size)) *
											(+item.avgPrice * +item.size)
										).toFixed(2)
									).replace('-', '')}{' '}
									{selectedPair?.quoteCoin}
								</Typography>
							</Box>
							<Box>
								<OpenInNewIcon
									sx={{ color: '#71757a' }}
									cursor='pointer'
									fontSize='small'
									onClick={() => {
										if (ticker?.data.symbol === item.symbol) {
											setPositionForShare({
												...item,
												lastPrice: String(ticker?.data.lastPrice ?? 0),
											})
										} else {
											setPositionForShare({
												...item,
												lastPrice: item.markPrice,
											})
										}
									}}
								/>
							</Box>
						</Box>
					) : (
						<Typography color={'#33383a'}>--</Typography>
					)}
				</td>
				<td style={{ paddingInline: '16px' }}>
					{!!item.curRealisedPnl ? (
						<Box>
							<Typography
								fontWeight={600}
								color={
									+item.curRealisedPnl >= 0
										? '#419E6A' // Green color for profit
										: '#D83232' // Red color for loss
								}
								sx={{ whiteSpace: 'nowrap' }}
							>
								<span>{Number(item.curRealisedPnl).toFixed(4)}</span>
								<span>{selectedPair?.quoteCoin}</span>
							</Typography>
							<Typography
								fontWeight={600}
								color={
									+item.curRealisedPnl >= 0
										? '#419E6A' // Green color for profit
										: '#D83232' // Red color for loss
								}
								sx={{ whiteSpace: 'nowrap' }}
							>
								<span>
									≈
									{(
										(+item.curRealisedPnl / (+item.avgPrice * +item.size)) *
										(+item.avgPrice * +item.size)
									).toFixed(2)}
								</span>
								<span>{selectedPair?.quoteCoin}</span>
							</Typography>
						</Box>
					) : (
						<Typography color={'#00B7F1'}>--</Typography>
					)}
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Box
						display='flex'
						justifyContent='center'
						alignItems='center'
						color='white'
					>
						{item.takeProfit || item.stopLoss ? (
							<Box
								display='flex'
								justifyContent='center'
								alignItems='center'
								gap='10px'
							>
								<Box
									sx={{ whiteSpace: 'nowrap' }}
									display='flex'
									justifyContent='center'
									alignItems='center'
									gap='5px'
								>
									<Typography color='#419E6A'>
										{!!item.takeProfit ? item.takeProfit : '--'}
									</Typography>
									/
									<Typography color='#D83232'>
										{!!item.stopLoss ? item.stopLoss : '--'}
									</Typography>
								</Box>
								<Button
									variant='contained'
									color='secondary'
									sx={{
										minWidth: 'auto',
										width: 30,
										height: 30,
										padding: '5px !important',
									}}
									onClick={() => setPositionForTPLS(item)}
								>
									<EditIcon sx={{ width: 15 }} />
								</Button>
							</Box>
						) : (
							<Button
								variant='contained'
								color='secondary'
								sx={{ padding: '5px !important' }}
								onClick={() => {
									if (item.symbol !== selectedPair?.symbol) {
										const filteredCurrencies = currencies?.result.list.filter(
											currency =>
												String(currency.symbol) === String(item.symbol)
										)
										if (filteredCurrencies && filteredCurrencies.length) {
											dispatch(setSelectedPair(filteredCurrencies[0]))
										}

										dispatch(setSelectedPairId(+item.symbol))
										dispatch(addPairChangingCounter())
									}
									setPositionForTPLS(item)
								}}
							>
								<Typography
									textTransform='initial'
									fontWeight='600'
									color='white'
								>
									+Add
								</Typography>
							</Button>
						)}
					</Box>
				</td>
				<td style={{ paddingInline: '16px' }}>
					<Box display='flex' justifyContent='center' alignItems='center'>
						<IconButton onClick={() => setPositionForClose(item)}>
							<ClearIcon
								fill={'#80848E'}
								sx={{ color: '#80848E', width: 15, height: 15 }}
							/>
						</IconButton>
					</Box>
				</td>
			</tr>
		)
	}
)

export const CloseLimitOrderForm = ({
	item,
	onSubmit,
}: {
	item: ByBitPosition
	onSubmit: SubmitHandler<CloseOrderLimitSchema>
}) => {
	const { ticker } = useByBit()
	const form = useForm<CloseOrderLimitSchema>({
		defaultValues: {
			type: 0,
			price: '',
		},
	})

	const handleSubmit = ({ type, price }: CloseOrderLimitSchema) => {
		if (
			ticker &&
			!isNaN(parseFloat(price)) &&
			((item.side === 'Buy' && parseFloat(price) < +ticker.data.lastPrice) ||
				(item.side === 'Sell' && parseFloat(price) > +ticker.data.lastPrice))
		) {
			form.setValue('price', ticker.data.lastPrice)
			return
		}
		onSubmit({ type, price })
	}

	return (
		<form
			style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
			onSubmit={form.handleSubmit(handleSubmit)}
		>
			<Controller
				control={form.control}
				name='type'
				render={({ field: { value, onChange } }) => (
					<>
						<Tabs
							style={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}
							value={value}
							onChange={(_, index) => onChange(index)}
						>
							<Tab
								sx={{
									'&.Mui-selected': {
										color: '#00B7F1',
									},
									flex: '1',
									minHeight: 0,
									padding: 1,
									textTransform: 'none',
								}}
								label='Limit'
							/>
							<Tab
								sx={{
									'&.Mui-selected': {
										color: '#00B7F1',
									},
									// minWidth: 0,
									flex: '1',
									width: '100%',
									minHeight: 0,
									padding: 1,
									textTransform: 'none',
								}}
								label='Market'
							/>
						</Tabs>
						{value == 0 ? (
							<>
								<Controller
									control={form.control}
									name='price'
									render={({ field: { onChange, ...field } }) => (
										<Box display='flex' flexDirection='column' gap='4px'>
											<Typography color='#80848E'>Order Price</Typography>
											<TextField
												sx={{ width: '100%' }}
												size='small'
												error={!!form.formState.errors.price?.message}
												helperText={form.formState.errors.price?.message}
												onChange={event => {
													const value = event.target.value.replace(
														/[^0-9.]+/g,
														''
													)
													onChange(
														value.split('.').length === 2
															? value
															: isNaN(parseFloat(value))
															? ''
															: String(parseFloat(value))
													)
												}}
												{...field}
											/>
										</Box>
									)}
								/>
							</>
						) : null}
					</>
				)}
			/>
			<Button type='submit' sx={{ width: '100%' }} variant='outlined'>
				Confirm close
			</Button>
		</form>
	)
}
