import './sample-exchange.module.scss'

import { ChangeEvent, useEffect, useMemo, useState } from 'react'

import { DefaultLayout } from '../../layouts/DefaultLayout/DefaultLayout'
import ExchangeContent from './components/ExchangeContent/ExchangeContent'
// import SelectAndConvertForm from './components/SelectForm/SelectForm';
import BgImage from './assets/simple-exchange-bg.png'
import { StyledContainer, StyledExchangeFlex } from './SampleExchange.styles'
// import { CoinListModal, ExchangeForm, ExchangeFormSubmitButton, ExchangeTypes, useExchangeForm } from './components';
import { byBitApi, ByBitFeeRateBody } from 'api'
// import { useSnackbar } from 'notistack';
import { exchangeApi } from 'api/exchange.api'
import { bybitWSUrl } from 'app.config'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useTypedSelector } from 'hooks/useTypedSelector'
import { useSnackbar } from 'notistack'
import { ByBitTicker } from 'providers/ByBitProvider'
import { getByBitWalletBalanceThunk } from 'store/wallets/wallets.thunk'
import { IMarketsList } from 'types/exchange.types'
import { ExchangeCoinListModal } from './ExchangeCoinListModal'
import { ExchangeFoot } from './ExchangeFoot'
import { ExchangeForm } from './ExchangeForm'
import { ExchangeFormHead } from './ExchangeFormHead'
import { ExchangeFormSubmitButton } from './ExchangeFormSubmitButton'
import { ExchangeFormLoader } from './ExchangeLoader'
import { ExchangeSuccessResultModal } from './ExchangeSuccessResultModal'
import { ExchangeTabHead } from './ExchangeTab'

export enum ExchangeTypes {
	Buy = 'Buy',
	Sell = 'Sell',
}

const index = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [selectType, setSelectType] = useState<ExchangeTypes>(ExchangeTypes.Buy)
	const [baseCoin, setBaseCoin] = useState<string | undefined>(undefined)
	const [quoteCoin, setQuoteCoin] = useState<string | undefined>(undefined)
	const [payAmount, setPayAmount] = useState<string>('')
	const [payError, setPayError] = useState<string | undefined>(undefined)
	const [modalType, setModalType] = useState<
		'baseCoin' | 'quoteCoin' | 'success' | undefined
	>(undefined)
	const [feeRate, setFeeRate] = useState<ByBitFeeRateBody | null>(null)
	const [ticker, setTicker] = useState<ByBitTicker | null>(null)
	const [successInfo, setSuccessInfo] = useState<
		| {
				time: number
				payCoin: string
				payAmount: string
				receiveCoin: string
				receiveAmount: string
		  }
		| undefined
	>(undefined)
	const [currencies, setCurrencies] = useState<IMarketsList | undefined>(
		undefined
	)
	const { enqueueSnackbar } = useSnackbar()

	const byBitWalletBalance = useTypedSelector(
		state => state.wallets.byBitWalletBalance
	)
	const dispatch = useAppDispatch()

	const selectCurrency = useMemo(() => {
		return currencies?.result.list.find(item => {
			return item.baseCoin === baseCoin && item.quoteCoin === quoteCoin
		})
	}, [currencies, baseCoin, quoteCoin])

	const payCoin = useMemo(() => {
		if (!baseCoin || !quoteCoin) return ''
		if (selectType === ExchangeTypes.Buy) {
			return quoteCoin
		}

		return baseCoin
	}, [selectType, baseCoin, quoteCoin])

	const receiveCoin = useMemo(() => {
		if (!baseCoin || !quoteCoin) return ''
		if (selectType === ExchangeTypes.Buy) {
			return baseCoin
		}

		return quoteCoin
	}, [selectType, baseCoin, quoteCoin])

	const buySellCoin = useMemo(() => {
		if (!payCoin || !receiveCoin) return ''
		if (selectType === ExchangeTypes.Buy) {
			return receiveCoin
		}

		return payCoin
	}, [selectType, payCoin, receiveCoin])

	const receiveAmount = useMemo(() => {
		if (!ticker?.data || !payAmount) return ''

		if (selectType === ExchangeTypes.Buy) {
			return String(+payAmount / +ticker.data.lastPrice)
		}

		return String(+payAmount * +ticker.data.lastPrice)
	}, [selectType, payAmount, ticker])

	const payWalletBalance = useMemo(() => {
		return byBitWalletBalance.data?.list[0].coin?.find(
			item => item.coin === payCoin
		)
	}, [byBitWalletBalance, payCoin])

	// console.log(selectCurrency)

	const handlePayAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value
		setPayError(undefined)
		const regex = /^\d+(\.\d*)?$/

		// console.log(selectCurrency)
		const minOrder =
			selectType === ExchangeTypes.Buy
				? (selectCurrency?.lotSizeFilter as any).minOrderAmt
				: (selectCurrency?.lotSizeFilter as any).minOrderQty
		const maxOrder =
			selectType === ExchangeTypes.Buy
				? (selectCurrency?.lotSizeFilter as any).maxOrderAmt
				: (selectCurrency?.lotSizeFilter as any).maxOrderQty

		if (value !== '' && !regex.test(value)) {
			return
		}

		const numValue = parseFloat(value)
		if (isNaN(numValue)) {
			setPayAmount(value)
			return
		}

		if (numValue > maxOrder) {
			setPayAmount(String(maxOrder))
			return
		}

		const valuePointIndex = value.indexOf('.')
		const valueLenAfterPoint = value.length - valuePointIndex - 1
		const precision =
			selectType === ExchangeTypes.Buy
				? (selectCurrency?.lotSizeFilter as any).quotePrecision
				: (selectCurrency?.lotSizeFilter as any).basePrecision
		const precisionPointIndex = precision.toString().indexOf('.')
		const precisionLenAfterPoint =
			precision.toString().length - precisionPointIndex - 1
		const minAmountBiggerZero = minOrder > 0
		if (!minAmountBiggerZero && valueLenAfterPoint < precisionLenAfterPoint) {
			setPayAmount(String(value))
			return
		}

		const valueLen = value.length
		const minAmountLen = minOrder.toString().length
		if (valueLen < minAmountLen) {
			setPayAmount(String(value))
			return
		}

		if (numValue < minOrder) {
			setPayAmount(String(minOrder))
			return
		}

		if (valueLenAfterPoint > precisionLenAfterPoint) {
			return
		}

		setPayAmount(String(numValue))
	}

	const handleModalClose = () => {
		setModalType(undefined)
	}

	const handlePayClick = useMemo(() => {
		if (selectType !== ExchangeTypes.Buy) return undefined

		return () => {
			setModalType('quoteCoin')
		}
	}, [selectType])

	const handleReceiveClick = useMemo(() => {
		if (selectType !== ExchangeTypes.Sell) return undefined

		return () => {
			setModalType('quoteCoin')
		}
	}, [selectType])

	const handleBuySellClick = () => {
		setModalType('baseCoin')
	}

	const handlePayAmountMaxClick = () => {
		// console.log(feeRate, payWalletBalance, selectCurrency)
		const maxValue =
			+(payWalletBalance?.availableToWithdraw ?? 0) -
			+(payWalletBalance?.availableToWithdraw ?? 0) *
				(+(feeRate?.list[0].takerFeeRate ?? 0) / 100)

		const precision =
			selectType === ExchangeTypes.Buy
				? (selectCurrency?.lotSizeFilter as any).quotePrecision
				: (selectCurrency?.lotSizeFilter as any).basePrecision
		const precisionPointIndex = precision.indexOf('.')
		const precisionLenAfterPoint = precision.length - precisionPointIndex - 1
		const result =
			Math.trunc(maxValue * 10 ** precisionLenAfterPoint) /
			10 ** precisionLenAfterPoint

		// const minOrder =
		// 	selectType === ExchangeTypes.Buy
		// 		? (selectCurrency?.lotSizeFilter as any).minOrderAmt
		// 		: (selectCurrency?.lotSizeFilter as any).minOrderQty

		// if (+(minOrder ?? 0) > result) {
		// 	setPayError(`Your balance is less than necessary: ${minOrder}`)
		// }

		setPayAmount(String(result))
	}

	const handleSelectCoin = (coin: string) => {
		if (
			(modalType === 'baseCoin' && quoteCoin === coin) ||
			(modalType === 'quoteCoin' && baseCoin === coin)
		) {
			enqueueSnackbar('You can not select the same coin', { variant: 'error' })
			return
		}

		setPayError(undefined)

		if (modalType === 'baseCoin') {
			setBaseCoin(coin)
			return
		}

		setQuoteCoin(coin)
	}

	const handleSelectTypeChange = (selectType: ExchangeTypes) => {
		setPayAmount('')
		setSelectType(selectType)
		setPayError(undefined)
	}

	const handleSubmit = async () => {
		try {
			if (!selectCurrency) return
			setPayError(undefined)
			// console.log(selectCurrency);
			// const payAmountNum = +payAmount;
			// const walletBalanceNum = +(payWalletBalance?.availableToWithdraw ?? 0);
			// console.log(payAmountNum, walletBalanceNum);

			// if (payAmountNum > walletBalanceNum) {
			//   setPayError(`Insufficient balance. Amount should be less than or equal to the wallet balance`);
			//   return;
			// }

			// if (payAmountNum <= 0) {
			//   setPayError(`Amount should be greater than zero`);
			//   return;
			// }

			// if (!selectType || !selectCurrency || !receiveAmount) {
			//   setPayError(`Please fill in all required fields`);
			//   return;
			// }

			// const minOrderQty = parseFloat(selectCurrency.lotSizeFilter.minOrderQty);
			// const maxOrderQty = parseFloat(selectCurrency.lotSizeFilter.maxOrderQty);

			// if (payAmountNum < minOrderQty) {
			//   setPayError(`Amount should be greater than or equal to the minimum order amount (${minOrderQty})`);
			//   return;
			// }

			// if (payAmountNum > maxOrderQty) {
			//   setPayError(`Amount should be less than or equal to the maximum order amount (${maxOrderQty})`);
			//   return;
			// }

			let marketUnit =
				selectType === ExchangeTypes.Buy ? 'quoteCoin' : 'baseCoin'
			const orderParams = {
				category: 'spot',
				qty: payAmount,
				marketUnit: marketUnit,
				orderType: 'Market',
				symbol: selectCurrency!.symbol,
				side: selectType === ExchangeTypes.Buy ? 'Buy' : 'Sell',
				isLeverage: 0,
			}

			console.log('Placing order with params:', orderParams)

			await byBitApi.placeOrder(orderParams)
			dispatch(getByBitWalletBalanceThunk({}))
			setSuccessInfo({
				time: new Date().getTime(),
				payCoin,
				payAmount,
				receiveCoin,
				receiveAmount,
			})
			setModalType('success')
			setPayAmount('0')
		} catch (err: any) {
			const error = err.response?.data?.error || err.message
			if (
				error &&
				!error.includes(
					`To proceed with trading, users must read through and confirm that they fully understand the project's risk disclosure document. For App users, please update your Bybit App to version 4.16.0 to process.`
				)
			) {
				setPayError(`Failed to place order: ${error}`)
			} else {
				setPayError('Something went wrong')
			}
		}
	}

	useEffect(() => {
		;(async () => {
			try {
				const res = await exchangeApi.getCurrencies({ category: 'spot' })
				const data = [...res.data.result.list].sort((a, b) => {
					const tickSizeA = parseFloat(a.priceFilter.tickSize)
					const tickSizeB = parseFloat(b.priceFilter.tickSize)
					if (tickSizeA > tickSizeB) return -1
					if (tickSizeA < tickSizeB) return 1
					return 0
				})
				setCurrencies({ result: { list: data } })
			} catch (err) {
				console.log(err)
			}
		})()
	}, [])

	useEffect(() => {
		if (!currencies || !feeRate) return
		setIsLoading(false)
	}, [currencies, feeRate])

	useEffect(() => {
		if (!currencies) return
		const { baseCoin, quoteCoin } = currencies.result.list[1]
		setBaseCoin(baseCoin)
		setQuoteCoin(quoteCoin)
	}, [currencies])

	useEffect(() => {
		;(async () => {
			if (!selectCurrency) return

			try {
				const res = await byBitApi.getFeeRate({ symbol: selectCurrency.symbol })
				setFeeRate(res)
			} catch (err) {
				console.log(err)
				console.log(currencies)
			}
		})()
	}, [selectCurrency])

	useEffect(() => {
		if (!selectCurrency) return
		const symbol = selectCurrency.symbol
		let byBitSocket: WebSocket
		;(async () => {
			byBitSocket = new WebSocket(`${bybitWSUrl}/v5/public/spot`)
			byBitSocket.onopen = () => {
				byBitSocket.send(
					JSON.stringify({
						op: 'subscribe',
						args: [`tickers.${symbol}`],
					})
				)
			}
			byBitSocket.onmessage = msg => {
				const data = JSON.parse(msg.data)
				if (data.topic === `tickers.${symbol}`) {
					setTicker(prev => ({
						...data,
						data: {
							...data.data,
							lastPrice: !!data.data.lastPrice
								? data.data.lastPrice
								: prev?.data.lastPrice,
							volume24h: !!data.data.volume24h
								? data.data.volume24h
								: prev?.data.volume24h,
							fundingRate: !!data.data.fundingRate
								? data.data.fundingRate
								: prev?.data.fundingRate,
							markPrice: !!data.data.markPrice
								? data.data.markPriec
								: prev?.data.markPrice,
							price24hPcnt: !!data.data.price24hPcnt
								? data.data.price24hPcnt
								: prev?.data.price24hPcnt,
							prevPrice24h: !!data.data.prevPrice24h
								? data.data.prevPrice24h
								: prev?.data.prevPrice24h,
							highPrice24h: !!data.data.highPrice24h
								? data.data.highPrice24h
								: prev?.data.highPrice24h,
							lowPrice24h: !!data.data.lowPrice24h
								? data.data.lowPrice24h
								: prev?.data.lowPrice24h,
							nextFundingTime: !!data.data.nextFundingTime
								? data.data.nextFundingTime
								: prev?.data.nextFundingTime,
						},
					}))
				}
			}
			byBitSocket.onclose = () => {
				setTicker(null)
			}
		})()

		return () => {
			byBitSocket.send(
				JSON.stringify({
					op: 'unsubscribe',
					args: [`tickers.${symbol}`],
				})
			)
		}
	}, [selectCurrency])

	return (
		<DefaultLayout mode='light'>
			<div
				style={{
					background: '#EBEBEB',
					position: 'relative',
					overflow: 'hidden',
					minHeight: 'calc(100vh - 81px)',
					paddingBottom: '81px',
				}}
			>
				<StyledContainer>
					<StyledExchangeFlex>
						<ExchangeContent />
						<div className='form-wrapper'>
							<img
								style={{
									position: 'absolute',
									top: '0px',
									right: '50%',
									transform: 'translateX(50%)',
									zIndex: 0,
								}}
								src={BgImage}
							/>
							<div className='form'>
								{isLoading && <ExchangeFormLoader />}
								<div>
									<ExchangeFormHead />
									<ExchangeTabHead
										selectType={selectType}
										onSelectType={handleSelectTypeChange}
									/>
									<ExchangeForm
										buySellCoin={buySellCoin}
										payCoin={payCoin}
										receiveCoin={receiveCoin}
										payAmount={payAmount}
										receiveAmount={
											!!receiveAmount && +receiveAmount < 1
												? (+receiveAmount).toFixed(22)
												: receiveAmount
										}
										payUsdtBalance={
											payWalletBalance?.availableToWithdraw ?? '0'
										}
										payError={payError}
										onPayAmountChange={handlePayAmountChange}
										onBuySellClick={handleBuySellClick}
										onPayClick={handlePayClick}
										onReceiveClick={handleReceiveClick}
										onPayAmountMaxClick={handlePayAmountMaxClick}
										currencies={currencies}
									/>
									<ExchangeFoot
										differences={
											!selectCurrency || !ticker
												? ''
												: selectType === ExchangeTypes.Buy
												? `${selectCurrency?.quoteCoin} 1 = ${
														1 / +(ticker?.data.lastPrice ?? 0)
												  } ${selectCurrency?.baseCoin}`
												: `${selectCurrency?.baseCoin} 1 = ${
														ticker?.data.lastPrice ?? 0
												  } ${selectCurrency?.quoteCoin}`
										}
										fee={`Fee: $${feeRate?.list[0].takerFeeRate ?? 0}`}
									/>
								</div>
								<ExchangeFormSubmitButton onClick={handleSubmit} />
							</div>
						</div>
					</StyledExchangeFlex>
				</StyledContainer>
			</div>
			<ExchangeCoinListModal
				isOpen={modalType === 'baseCoin' || modalType === 'quoteCoin'}
				coinPropName={
					modalType === 'baseCoin'
						? 'baseCoin'
						: modalType === 'quoteCoin'
						? 'quoteCoin'
						: undefined
				}
				pairCoinName={
					selectType === ExchangeTypes.Buy
						? modalType === 'baseCoin'
							? payCoin
							: modalType === 'quoteCoin'
							? receiveCoin
							: undefined
						: modalType === 'baseCoin'
						? receiveCoin
						: modalType === 'quoteCoin'
						? payCoin
						: undefined
				}
				currencies={currencies?.result.list ?? []}
				isShowBalance={
					(selectType === ExchangeTypes.Buy && modalType === 'quoteCoin') ||
					(selectType === ExchangeTypes.Sell && modalType === 'baseCoin')
				}
				onClose={handleModalClose}
				onSelectCoin={handleSelectCoin}
			/>
			<ExchangeSuccessResultModal
				isOpen={modalType === 'success'}
				info={successInfo}
				onClose={() => setModalType(undefined)}
			/>
		</DefaultLayout>
	)
}

// function formatStringToFloat(str: string): string {
//   const cleanedStr = str.replace(/[^0-9.]/g, '');

//   const parts = cleanedStr.split('.');

//   let formattedStr = parts[0];
//   if (parts.length > 1) {
//       formattedStr += '.' + parts.slice(1).join('');
//   }

//   const floatNumber = parseFloat(formattedStr);

//   if (isNaN(floatNumber)) {
//       return "0";
//   }

//   if (cleanedStr.endsWith('.')) {
//       return formattedStr;
//   }

//   return floatNumber.toString();
// }

export default index
