import { FC, useEffect, useMemo, useState } from "react";

import { AdditionalLayout } from "components";
import { NavLink, useSearchParams } from "react-router-dom";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import EastIcon from "@mui/icons-material/East";
import { Alert, Button, CircularProgress, Grid, Snackbar } from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";

import { walletsApi } from "api";
import { useAppDispatch } from "hooks/useAppDispatch";

import { postTransferThunk } from "store/wallets/wallets.thunk";

import {
  StyledCurrencySmallImage,
  StyledCurrencyValue,
  StyledCurrencyWrapper,
  StyledDialog,
  StyledDialogTitle,
  StyledFieldWrapper,
  StyledGridContainer,
  StyledGridItem,
  StyledGridItemTitle,
  StyledInfoAmount,
  StyledInfoFee,
  StyledInfoTitle,
  StyledInfoValue,
  StyledInfoWrapper,
  StyledInputSearch,
  StyledNetworkText,
  StyledNetworkWrapper,
  StyledSearchIcon,
  StyledSelectSubWrapper,
  StyledSelectTitle,
  StyledSelectWrapper,
  StyledTextField,
  StyledWithdrawButton,
  StyledWithdrawWrapper,
  StyledWrapper,
  WorkItem,
  WorkItemText,
  WorkItemTitle,
} from "../CommonComponents.styles";
import { Controller } from "react-hook-form";
import { useWithdrawForm } from "hooks/useWithdrawForm";
import { CoinsContainer } from "components/container/CoinsContainer";
import { NetworksContainer } from "components/container/NetworksContainer";
import { ListPaginationContainer } from "components/container/ListPaginationContainer";
import { useTypedSelector } from "hooks/useTypedSelector";
import { CoinIcon } from "components/UI/atoms/CoinIcon/CoinIcon";

const Withdraw: FC = (): JSX.Element | null => {
  const [search, setSearch] = useState<string>("");
  const [searchParam] = useSearchParams();
  const coinParam = searchParam.get("coin");
  const { form } = useWithdrawForm();
  const [openCoins, setOpenCoins] = useState<boolean>(false);
  const [openNetwork, setOpenNetworks] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [fee, setFee] = useState<any>(0);
  const byBitWalletBalance = useTypedSelector(
    (state) => state.wallets.byBitWalletBalance
  );
  const coin = form.watch("coin");
  const amount = form.watch("amount");
  const network = form.watch("network");
  // const address = form.watch("address");
  const dispatch = useAppDispatch();

  const coinBalance = useMemo(() => {
    if (!coin || !byBitWalletBalance.data) return null;
    return byBitWalletBalance.data.list[0].coin?.find(
      (coinItem) => coinItem.coin === coin?.coin
    );
  }, [byBitWalletBalance, coin]);

  useEffect(() => {
    if (!coinParam) return;
    form.setValue("coin", { coin: coinParam });
  }, [coinParam]);

  const handleSubmit = () => {
    dispatch(
      postTransferThunk({
        currency: form.getValues("coin")?.coin,
        to_address: form.getValues("address"),
        amount: +form.getValues("amount"),
        chain: form.getValues("network").chain,
        tag: form.getValues("tagDeposit")
      })
    ).then(() => {
      form.reset();
    });
  };

  const computedTotal = useMemo(() => {
    if (!amount || !fee.fee) return "";
    return +amount + +fee.fee;
  }, [fee, amount]);

  // useEffect(() => {
  //   (async () => {
  //     if(!selectedPair) return;
  //     const res = await byBitApi.getFeeRate({ baseCoin: selectedPair.baseCoin, symbol: selectedPair.symbol });
  //     setFeeRate(res);
  //   })()
  // }, [selectedPair])

  // useEffect(() => {
  //   if (!coin || !amount || !address) return;
  //   walletsApi
  //     .widthdrawFee({
  //       currency: coin?.coin,
  //       amount,
  //       to_address: address,
  //     })
  //     .then((res) => {
  //       setFee(res);
  //     });
  // }, [coin, address, amount]);

  return (
    <AdditionalLayout>
      <StyledGridContainer
        container
        style={{
          maxHeight: "calc(100vh - 0)",
          overflow: " auto",
          background: "#F5F5F5",
          display: "flex",
          padding: "20px 32px 56px 0px",
          gap: "20px",
        }}
        justifyContent="space-between"
      >
        <StyledGridItem item xs={7.5}>
          <StyledWithdrawWrapper>
            <StyledGridItemTitle>Withdraw Crypto</StyledGridItemTitle>

            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <Controller
                control={form.control}
                name="coin"
                render={({ field: { value } }) => (
                  <StyledSelectWrapper>
                    <StyledSelectTitle>Select coin</StyledSelectTitle>
                    <StyledSelectSubWrapper
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => setOpenCoins(true)}
                      placeholder="Select coin"
                    >
                      <Box display="flex" alignItems="center">
                        {value ? (
                          <CoinIcon coinName={value.coin?.toLowerCase().trim()} style={{ width: 27, height: 26, marginRight: 20 }} />
                        ) : (
                          false
                        )}
                        <div>{value?.coin}</div>
                      </Box>

                      <ArrowDropDownIcon />
                    </StyledSelectSubWrapper>
                  </StyledSelectWrapper>
                )}
              />

              <Controller
                control={form.control}
                name="address"
                render={({ field: { value, onChange } }) => (
                  <StyledFieldWrapper>
                    <StyledSelectTitle>Address of receiver</StyledSelectTitle>
                    <StyledTextField
                      inputProps={{
                        type: "text",
                        pattern: "[A-Za-z0-9]+",
                      }}
                      value={value}
                      onChange={onChange}
                      sx={{
                        "&::before": {
                          borderBottom: "1px solid #EEEEEE !important",
                        },
                      }}
                      placeholder="Enter address"
                    />
                    {!!form.formState.errors.address && (
                      <div
                        style={{
                          paddingTop: "10px",
                          color: "#f44336",
                        }}
                      >
                        {form.formState.errors.address?.message}
                      </div>
                    )}
                  </StyledFieldWrapper>
                )}
              />
              <Controller
                control={form.control}
                name="network"
                render={({ field: { value } }) => (
                  <StyledSelectWrapper>
                    <StyledSelectTitle>Select network</StyledSelectTitle>
                    <StyledSelectSubWrapper
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      onClick={() => setOpenNetworks(true)}
                      placeholder="Select network"
                    >
                      <Box display="flex" alignItems="center">
                        <div>{value?.chain}</div>
                      </Box>

                      <ArrowDropDownIcon />
                    </StyledSelectSubWrapper>
                  </StyledSelectWrapper>
                )}
              />

              <Controller
                control={form.control}
                name="amount"
                render={({ field: { value, onChange } }) => (
                  <StyledFieldWrapper>
                    <StyledSelectTitle>Withdraw amount</StyledSelectTitle>
                    <StyledTextField
                      type="number"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={value === 0 ? "" : String(value)}
                      onChange={onChange}
                      sx={{
                        "&::before": {
                          borderBottom: "1px solid #EEEEEE !important",
                        },
                      }}
                      placeholder="Minimum 0.0001"
                    />
                  </StyledFieldWrapper>
                )}
              />
              {network && network.chain.toLowerCase() === 'xrp' ? 
                <Controller
                  control={form.control}
                  name="tagDeposit"
                  render={({ field: { value, onChange } }) => (
                    <StyledFieldWrapper>
                      <StyledSelectTitle>Deposit tag/memo</StyledSelectTitle>
                      <StyledTextField
                        type="text"
                        value={value}
                        onChange={onChange}
                        sx={{
                          "&::before": {
                            borderBottom: "1px solid #EEEEEE !important",
                          },
                        }}
                        placeholder="Enter tag/memo"
                      />
                    </StyledFieldWrapper>
                  )}
                />
                :
                null
            }
                    {/* <StyledTextField
                      type="number"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      // value={value === 0 ? "" : String(value)}
                      onChange={onChange}
                      sx={{
                        "&::before": {
                          borderBottom: "1px solid #EEEEEE !important",
                        },
                      }}
                      placeholder="Minimum 0.0001"
                    /> */}
                  {/* </StyledFieldWrapper> */}
                {/* )}
              /> */}

              <StyledInfoWrapper display="flex" justifyContent="space-between">
                <div>
                  <StyledInfoTitle>{coin?.coin} balance</StyledInfoTitle>
                  <StyledInfoValue>
                    {coinBalance?.walletBalance || 0} {coin?.coin}
                  </StyledInfoValue>
                </div>
                <div>
                  <StyledInfoTitle>Network fee</StyledInfoTitle>
                  <StyledInfoValue>{coin?.coin ?? "--"}</StyledInfoValue>
                </div>
              </StyledInfoWrapper>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <StyledInfoAmount>
                    {computedTotal} {coin?.coin}
                  </StyledInfoAmount>
                  <StyledInfoFee>
                    {fee.fee} {coin?.coin} network fee included
                  </StyledInfoFee>
                </div>

                <StyledWithdrawButton
                  type="button"
                  onClick={handleSubmit}
                  disabled={form.formState.isSubmitting}
                  variant="outlined"
                >
                  Withdraw
                </StyledWithdrawButton>
              </Box>
            </form>
          </StyledWithdrawWrapper>
        </StyledGridItem>

        <Grid item xs={4}>
          <Box display="flex" justifyContent="center">
            <NavLink to="/dashboard/deposit">
              <Button
                style={{
                  border: "1.5px solid #00B7F1",
                  fontWeight: "400",
                  fontSize: "16px",
                  textTransform: "capitalize",
                  width: 228,
                  height: 49,
                  padding: 0,
                  color: "#00B7F1",
                }}
                variant="outlined"
                endIcon={<EastIcon />}
              >
                Deposit Crypto
              </Button>
            </NavLink>
          </Box>

          <StyledWrapper>
            <StyledGridItemTitle>How it works</StyledGridItemTitle>

            <WorkItem>
              <img
                src="/images/works/works1.svg"
                alt="Select the crypto you want to withdraw"
              />
              <WorkItemTitle>
                Select the crypto you want to withdraw
              </WorkItemTitle>
              <WorkItemText>
                Choose out of the list of coins which you wish to withdraw.
              </WorkItemText>
            </WorkItem>

            <WorkItem>
              <img
                src="/images/works/works2.svg"
                alt="Select the network you want to use"
              />
              <WorkItemTitle>Select the network you want to use</WorkItemTitle>
              <WorkItemText>
                Select which network you would like to use for the withdrawal.
              </WorkItemText>
            </WorkItem>

            <WorkItem>
              <img
                src="/images/works/works3.svg"
                alt="Input the amount and press withdraw"
              />
              <WorkItemTitle>Input the amount and press withdraw</WorkItemTitle>
              <WorkItemText>
                Enter how much you would like to withdraw and press the withdraw
                button.
              </WorkItemText>
            </WorkItem>
          </StyledWrapper>
        </Grid>

        <StyledDialog
          sx={{ overflow: "hidden" }}
          open={openCoins}
          onClose={() => setOpenCoins(false)}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <StyledDialogTitle>Select crypto to withdraw</StyledDialogTitle>
            <CloseIcon
              onClick={() => setOpenCoins(false)}
              style={{
                position: "absolute",
                right: 12,
                cursor: "pointer",
                top: 12,
              }}
            />
          </div>

          <FormControl variant="standard">
            <StyledInputSearch
              onChange={(e) => setSearch(e.target.value)}
              style={{ color: "#000", fontSize: "14px", lineHeight: "21px" }}
              id="input-with-icon-adornment"
              placeholder="Search crypto name"
              startAdornment={
                <InputAdornment position="start">
                  <StyledSearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <CoinsContainer
            render={({
              notDuplicatedCoins: coins,
              loading,
              error,
              onRequest,
            }) => {
              return (
                <>
                  {!!error ? (
                    <>{error}</>
                  ) : (
                    <>
                      {coins && (
                        <ListPaginationContainer
                          onPaginate={() => {
                            if (!loading && !!coins?.nextPageCursor)
                              onRequest({
                                cursor: coins.nextPageCursor,
                              });
                          }}
                          render={({ listRef }) => {
                            return (
                              <div ref={listRef} style={{ overflowY: "auto" }}>
                                {coins.configList
                                  .filter((item) =>
                                    item.coin.toLowerCase().includes(search)
                                  )
                                  .map((item, index) => (
                                    <StyledCurrencyWrapper
                                      key={index}
                                      onClick={() => {
                                        setOpenCoins(false);
                                        form.setValue("coin", item);
                                      }}
                                      display="flex"
                                      alignItems="center"
                                    >
                                      <StyledCurrencySmallImage
                                        src={`https://assets.coincap.io/assets/icons/${item.coin?.toLowerCase().trim()}@2x.png`}
                                      />
                                      <span>
                                        {item.coin}{" "}
                                        <span
                                          style={{
                                            color: "#80848E",
                                            fontWeight: 500,
                                          }}
                                        >
                                          {item.coinShowName}
                                        </span>
                                      </span>
                                    </StyledCurrencyWrapper>
                                  ))}
                              </div>
                            );
                          }}
                        />
                      )}
                      {!coins && loading && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            }}
          />
        </StyledDialog>

        <StyledDialog open={openNetwork} onClose={() => setOpenNetworks(false)}>
          <StyledDialogTitle>Select network</StyledDialogTitle>

          <StyledNetworkText>
            Make sure to select the right network to prevent assets from
            becoming lost!
          </StyledNetworkText>

          <Controller
            control={form.control}
            name="coin"
            render={({ field: { value } }) => (
              <>
                {!!value ? (
                  <NetworksContainer
                    coin={value?.coin}
                    render={({ networks, loading }) => (
                      <>
                        {!!networks ? (
                          <div style={{ overflowY: "auto" }}>
                            {networks.rows.map((item) => (
                              <>
                                {item.chains.map((chain, index) => (
                                  <StyledNetworkWrapper
                                    key={index}
                                    onClick={() => {
                                      setOpenNetworks(false);
                                      form.setValue("network", chain);
                                    }}
                                  >
                                    {chain.chainType}
                                    <StyledCurrencyValue>
                                      <div>{chain.chain}</div>
                                    </StyledCurrencyValue>
                                  </StyledNetworkWrapper>
                                ))}
                              </>
                            ))}
                          </div>
                        ) : loading ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress />
                          </div>
                        ) : null}
                      </>
                    )}
                  />
                ) : null}
              </>
            )}
          />
        </StyledDialog>

        {/* <StyledDialog open={openQr} onClose={setOpenQr.bind(null, false)}>
          <StyledDialogTitleQr>QR Code</StyledDialogTitleQr>
          <DialogContent>
            <StyledNetworkTextQr>
              Scan the QR code for the address
            </StyledNetworkTextQr>
            <Box display="flex" justifyContent="center">
              <img src="/images/qr.svg" alt="qr" />
            </Box>

            <StyledWarningWrapper
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <StyledWarningAmberRoundedIcon />
              <span>Only send {currency?.currency} to this address!</span>
            </StyledWarningWrapper>
          </DialogContent>
        </StyledDialog> */}
      </StyledGridContainer>
      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={setOpenSuccess.bind(null, false)}
      >
        <Alert
          onClose={setOpenSuccess.bind(null, false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          withdraw was successful
        </Alert>
      </Snackbar>
    </AdditionalLayout>
  );
};

export default Withdraw;
